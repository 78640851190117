<template>
  <div>
    <CCol md="12" lg="12">
      <CCard class="shadow-sm">
        <CCardBody>
          <CRow>
            <CCol md="10" sm="9">
              <div class="row">
                <img src="/img/icon/store.png" style="width: 32px; height:32px; margin-right: 8px;"/>
                <select class="custom-select col-11" v-model="shopObjectId">
                  <option selected>เลือกสาขา..</option>
                  <option
                    v-for="shop in shops"
                    :key="shop.objectId"
                    :value="shop.objectId"
                  >
                    {{ shop.shopName }} - {{ shop.branchName }}
                  </option>
                </select>
              </div>
            </CCol>
            <CCol sm="3" md="2" v-if="loadingButton === true">
              <CButton v-on:click="getProductReport()" block color="success">
                {{ $t('submit') }}
              </CButton>
            </CCol>
            <CCol sm="2" md="2" v-else-if="loadingButton === false">
              <CButton block color="success" disabled>
                <CSpinner color="white" size="sm" /> กำลังโหลด . . .
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol md="12" lg="12">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <h2 class="col-md-10  col-sm-9 font-weight-bold">
              รายงานการขายแยกตามสินค้า
            </h2>
            <div class="col-md-2 col-sm-3 text-right text-success">
              <CButton v-on:click="exportProductReport()" block color="info">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <hr />
              <CDataTable
                :items="items"
                :fields="fields"
                :loading="loading"
                hover
                sorter
                border
              >
              <template #no-items-view>
                <div class="text-center my-5">
                  <h4 style="color: #ced2d8;">
                    {{ $t('noItem') }}
                  </h4>
                </div>
              </template>
              </CDataTable>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getProductReport">
          </pagination>
        </CCardBody>
      </CCard>
    </CCol>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import util from '@/util/util'
import Pagination from '@/containers/Pagination'
import standard from '@/containers/StandardPlan'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      data: [],
      loadingButton: true,
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      'shops',
      'date',
      'users',
      'permissionReport',
      'permissionExport',
    ]),
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        { key: 'id', label: 'ลำดับ', sorter: false },
        { key: 'PLUCode', label: 'รหัสสินค้า', sorter: false },
        { key: 'name', label: 'ชื่อสินค้า', sorter: false },
        { key: 'category', label: 'กลุ่มสินค้า', sorter: false },
        { key: 'quantity', label: 'ขายได้จำนวน', _classes: 'text-right' },
        {
          key: 'netAmount',
          label: 'ยอดขาย',
          _classes: 'text-right',
          sorter: false,
        },
      ]
    },
    items() {
      let data = this.data
      let detail = []

      for (let i = 0; i < data.length; i++) {
        detail.push({
          id: i + 1,
          PLUCode: data[i].product.pluCode,
          name: data[i].product.pluName,
          category: data[i].product.categoryName,
          quantity: data[i].quantity,
          netAmount: util.convertCurrency(data[i].totalAmount),
        })
      }
      return detail
    },
  },
  created() {
    this.getProductReport()
  },
  methods: {
    ...util,
    getProductReport() {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`

      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }

      this.loading = true
      axios({
        url: '/receipt/v1.0/productquantitysell/' + uid + '/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportProductReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }

      report({
        url: '/receipt/v1.0/productquantitysell/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานการขายแยกตามกลุ่มสินค้า.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
