<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="setShop"
    ></select-shop>
    <CCard>
      <CCardBody class="text-center">
        <div v-if="$i18n.locale === 'th'">
          <h2 class="font-weight-bold mt-3 mb-4">
            แผนการใช้งานปัจจุบันของคุณคือ Standard (ใช้งานแบบออฟไลน์)
          </h2>
          <h4 class="mb-4">
            ติดต่อฝ่ายบริการลูกค้าเพื่อใช้บริการ Silom Dashboard
          </h4>
        </div>
        <div v-else>
          <h2 class="font-weight-bold mt-3 mb-4">
            Your current plan is <b>Standard </b> (Offline).
          </h2>
          <h4 class="mt-3">
            Please contact our support to continue.
          </h4>
        </div>
        <CImg src="../../img/exp.jpg" class="img-fluid mt-3" width="50%" />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      loadingButton: true,
    }
  },
  computed: {
    ...mapGetters(['shops', 'shopObjectId']),
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
  },
  methods: {
    setShop() {
      this.$store.dispatch('getPermission')
    },
  },
}
</script>
